@charset "utf-8";

/* Default */
@import "__normalize";
@import "__mixin";

/* Adjust if you needed */
@import "_variable";
@import "_default";
@import "_zindex";

/* [ Sample ] */
// FontSize        - @include fz($size: 14);
// Clearfix        - @extend %clearfix;
// No List Style   - @extend %nolist;

// Width > %       - @include width2p(640, 300);
// Height > %      - @include height2p(500, 300);
// Margin > %      - @include margin2p(640, 20, 5, 20, 5);
// Padding > %     - @include padding2p(640, 20, 5, 20, 5);

// Possition > %   - @include left2p(640, 50);
//                 - @include top2p(640, 50);
//                 - @include right2p(640, 50);
//                 - @include bottom2p(640, 50);


.pc {
	display: block;
}
.sp {
	display: none;
}


/*-----------------------------------------------------*/
// header
/*-----------------------------------------------------*/

.header {
	width: 100%;
}

.header__idx {
	height: 800px;
	background: url(../images/main_visual.png) no-repeat center center;
	background-size: cover;
	
	.header__inner {
		max-width: 1040px;
		height: 800px;
		width: 100%;
		margin: 0 auto;
		position: relative;
	}
	
	.header__ttl {
		position: absolute;
		bottom: 100px;
		left: 0;
		width: 100%;
		text-align: center;
		color: #fff;
		z-index: 10;
	}
	.header__lead {
		@include fz($size: 40);
		font-style: normal;
		font-weight: 900;
		line-height: 1;
		margin-bottom: 17px;
	}
	h1 {
		@include fz($size: 70);
		font-style: normal;
		font-weight: 900;
		line-height: 1;
		margin: 0;
		padding: 0;
	}
	
	.header__logo {
		position: absolute;
		top: 14px;
		left: 20px;
	}
	
	.header__scroll {
		position: absolute;
		width: 100%;
		text-align: center;
		bottom: 30px;
		left: 0;
		
		a {
			&:hover {
				opacity: .5;
			}
		}
	}
}

.header__item {
	height: 560px;
	background: url(../images/item_bg.png) no-repeat center center;
	background-size: cover;
	
	.header__inner {
		max-width: 1040px;
		height: 560px;
		width: 100%;
		margin: 0 auto;
		position: relative;
	}
	
	.header__logo__s {
		position: absolute;
		top: 30px;
		left: 20px;
	}
	
	.header__item__h {
		position: absolute;
		top: 0;
		right: 0;
		width: 380px;
		height: 560px;
		background: rgba(0,0,0,.5);
		padding: 40px 40px 0 40px;
	}
	
	.header__item__lead {
		width: 100%;
		border: 2px solid #fff;
		text-align: center;
		@include fz($size: 18);
		font-style: normal;
		font-weight: 900;
		color: #fff;
		padding: 12px 0 8px 0;
		margin-bottom: 15px;
		line-height: 1;
	}
	
	h1 {
		@include fz($size: 22);
		font-style: normal;
		font-weight: 700;
		text-align: center;
		line-height: 1.3;
		margin: 0 0 8px 0;
		padding: 0;
		color: #fff;
	}
	
	.header__item__img {
		text-align: center;
		
		img {
			width: 100%;
			max-width: 285px;
			height: auto;
		}
	}
}


/*-----------------------------------------------------*/
// main
/*-----------------------------------------------------*/

.main {
}


/*-----------------------------------------------------*/
// exp
/*-----------------------------------------------------*/

.exp {
	width: 100%;
	background: #000;
	padding: 60px 0 50px 0;
	
	@include e(_inner) {
		width: 100%;
		max-width: 850px;
		margin: 0 auto 25px auto;
		display: flex;
		justify-content: space-between;
	}
	
	@include e(_img) {
		width: 44%;
		
		img {
			width: 100%;
			height: auto;
		}
	}
	
	@include e(_txt) {
		width: 55%;
		padding-top: 55px;
		
		p {
			@include fz($size: 29);
			font-style: normal;
			font-weight: 900;
			line-height: 1.2;
			color: #fff;
			letter-spacing: -0.01em;
			margin-bottom: 35px;
		}
		
		dl {
			dt {
				@include fz($size: 24);
				color: #fff;
				font-style: normal;
				font-weight: 900;
				line-height: 1;
				margin-bottom: 15px;
			}
			dd {
				@include fz($size: 74);
				color: #fff021;
				font-style: normal;
				font-weight: 900;
				line-height: 1;
			}
		}
	}
	
	@include e(_btn) {
		width: 300px;
		margin: 0 auto;
		
		a {
			display: block;
			border: 3px solid #fff;
			color: #fff;
			@include fz($size: 16);
			font-style: normal;
			font-weight: 900;
			text-align: center;
			text-decoration: none;
			background: url(../images/link_mark.png) no-repeat 93% center;
			line-height: 1;
			overflow: hidden;
			
			span {
				display: block;
				border: 2px solid #000;
				padding: 21px 0 18px 0;
				transition: .3s;
			}
			
			&:hover {
				background: url(../images/link_mark.png) no-repeat 96% center;
				
				span {
					border: 2px solid #fff;
				}
			}
		}
	}
}


/*-----------------------------------------------------*/
// banner
/*-----------------------------------------------------*/

.banner {
	width: 100%;
	background: #badb3a;
	padding: 50px 0 20px 0;
	
	@include e(_inner) {
		width: 100%;
		max-width: 1050px;
		padding: 0 20px;
		margin: 0 auto;
		
		ul {
			width: 100%;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			
			&::after{
				content: "";
				display: block;
				width: 317px;
			}
			
			li {
				width: 307px;
				height: 147px;
				margin-bottom: 30px;
				position: relative;
				
				&::after {
					content: "";
					position: absolute;
					top: 7px;
					left: 7px;
					background: #fff;
					width: 300px;
					height: 140px;
					z-index: 1;
				}
				
				a {
					display: block;
					width: 300px;
					height: 140px;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 2;
					transition: .2s;
					
					&:hover {
						top: 7px;
						left: 7px;
						opacity: .5;
					}
				}
			}
		}
	}
}


/*-----------------------------------------------------*/
// motto
/*-----------------------------------------------------*/

.motto {
	padding: 30px 20px;
	max-width: 940px;
	width: 100%;
	margin: 0 auto;
	
	
	h2 {
		width: 100%;
		max-width: 865px;
		margin: 0 auto 30px auto;
		
		img {
			width: 100%;
			height: auto;
		}
	}
	
	h3 {
		@include fz($size: 44);
		color: #2aa738;
		line-height: 1.2;
		text-align: center;
		font-style: normal;
		font-weight: 900;
		margin-bottom: 30px;
	}
	
	@include e(_inner) {
		width: 100%;
		display: flex;
		justify-content: space-between;
		
		dl {
			width: 45%;
			padding-top: 10px;
			dt {
				@include fz($size: 30);
				font-style: normal;
				font-weight: 900;
				margin-bottom: 10px;
			}
			dd {
				@include fz($size: 15);
				font-style: normal;
				font-weight: 400;
				line-height: 2;
			}
		}
	}
		
	@include e(_mechanism) {
		width: 55%;
		
		img {
			width: 100%;
			height: auto;
		}
	}
}


/*-----------------------------------------------------*/
// detail
/*-----------------------------------------------------*/

.detail {
	width: 100%;
	max-width: 1000px;
	background: #49a4eb;
	margin: 0 auto;
	padding: 50px 50px 70px 50px;
	
	@include e(_list) {
		ul {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			
			li {
				width: 47%;
				margin-bottom: 45px;
				
				dl {
					width: 100%;
					dt {
						width: 100%;
						@include fz($size: 26);
						font-style: normal;
						font-weight: 900;
						color: #fff;
						line-height: 1.2;
						padding-bottom: 10px;
						margin-bottom: 10px;
						border-bottom: 5px solid #fff;
					}
					dd {
						@include fz($size: 15);
						font-style: normal;
						font-weight: 400;
						color: #fff;
						line-height: 2;
						
						.graph {
							float: right;
							margin: 0 0 10px 10px;
						}
						
						&.note {
							clear: both;
							@include fz($size: 11);
						}
					}
				}
				
				&:nth-child(1) {
					.detail__img {
						width: 100%;
						text-align: center;
						img {
							max-width: 410px;
							width: 100%;
							height: auto;
						}
					}
				}
				&:nth-child(2) {
					.detail__img {
						padding-top: 30px;
						width: 100%;
						text-align: center;
						img {
							max-width: 410px;
							width: 100%;
							height: auto;
						}
					}
				}
			}
		}
		
		
	}
	
	@include e(_voice) {
		width: 100%;
		max-width: 800px;
		margin: 0 auto;
		background: #fff;
		padding: 50px;
		box-shadow: 7px 7px 0 0 #4293d3;
		
		h3 {
			@include fz($size: 26);
			font-style: normal;
			font-weight: 700;
			line-height: 1.2;
			margin-bottom: 20px;
		}
		
		@include e(_inner) {
			display: flex;
			justify-content: space-between;
		}
		
		@include e(_ph) {
			width: 260px;
		}
		
		@include e(_body) {
			flex: 1;
			
			dl {
				dt {
					@include fz($size: 22);
					font-style: normal;
					font-weight: 700;
					line-height: 1.2;
					margin-bottom: 10px;
					
					span {
						display: inline-block;
						@include fz($size: 16);
					}
				}
				dd {
					font-style: normal;
					font-weight: 400;
					@include fz($size: 15);
					line-height: 2;
					
					&.post {
						@include fz($size: 13);
						line-height: 1.3;
						margin-bottom: 20px;
					}
				}
			}
		}
	}
}


/*-----------------------------------------------------*/
// lineup
/*-----------------------------------------------------*/

.lineup {
	text-align: center;
	
	@include e(_ttl) {
		display: inline-block;
		margin: 0 auto 35px auto;
		
		h2 {
			@include fz($size: 40);
			font-style: normal;
			font-weight: 900;
			color: #2aa738;
			line-height: 1.2;
			text-align: left;
			margin-bottom: 10px;
			letter-spacing: .05em;
		}
		
		@include e(_lead) {
			@include fz($size: 29);
			font-style: normal;
			font-weight: 700;
			width: 100%;
			background: #2aa738;
			color: #fff;
			text-align: center;
			line-height: 1;
			padding: 12px 10px 7px 10px;
		}
	}
	
	@include e(_list) {
		width: 100%;
		max-width: 940px;
		padding: 0 20px;
		margin: 0 auto 40px auto;
		
		>ul {
			>li {
				background: #f6f6f6;
				margin-bottom: 25px;
				padding: 35px 40px 30px 40px;
				text-align: left;
				
				&:nth-child(1) {
					border-left: 5px solid #89b539;
					h3 {
						color: #89b539;
					}
				}
				&:nth-child(2) {
					border-left: 5px solid #ec6414;
					h3 {
						color: #ec6414;
					}
				}
				&:nth-child(3) {
					border-left: 5px solid #0093d9;
					h3 {
						color: #0093d9;
					}
				}
			}
		}
		
		@include e(_wrap) {
			display: flex;
			justify-content: space-between;
			padding-bottom: 30px;
			overflow: hidden;
		}
		
		@include e(_img) {
			width: 21%;
			img {
				width: 100%;
				max-width: 170px;
				height: auto;
			}
		}
		
		@include e(_data) {
			width: 76%;
			
			h3 {
				@include fz($size: 28);
				font-style: normal;
				font-weight: 700;
				line-height: 1.2;
				margin-bottom: 10px;
			}
		}
		
		@include e(_name) {
			margin-bottom: 18px;
			
			dl {
				dt {
					@include fz($size: 18);
					font-style: normal;
					font-weight: 700;
					margin-bottom: 7px;
					line-height: 1;
				}
				dd {
					@include fz($size: 36);
					font-style: normal;
					font-weight: 900;
					line-height: 1;
				}
			}
		}
		
		@include e(_point) {
			padding-bottom: 13px;
			ul {
				li {
					@include fz($size: 14);
					font-style: normal;
					font-weight: 700;
					margin-bottom: 5px;
					background: url(../images/check_mark.png) no-repeat left 4px;
					padding-left: 20px;
				}
			}
			
		}
		
		@include e(_detail) {
			background: #fff;
			padding: 15px 20px 13px 20px;
			@include fz($size: 13);
			font-style: normal;
			font-weight: 400;
		}
		
		@include e(_btn) {
			text-align: center;
			
			a {
				display: inline-block;
				width: 100%;
				max-width: 320px;
				border-radius: 6px;
				color: #fff;
				background: #2aa738;
				@include fz($size: 18);
				font-style: normal;
				font-weight: 900;
				text-align: center;
				padding: 22px 10px 18px 10px;
				line-height: 1;
				text-decoration: none;
				
				&:hover {
					opacity: .5;
				}
			}
		}
	}
}


/*-----------------------------------------------------*/
// flow
/*-----------------------------------------------------*/

.flow {
	width: 100%;
	max-width: 840px;
	padding: 0 20px;
	margin: -45px auto 80px auto;
	
	@include e(_h) {
		width: 100%;
		margin: 0 auto 0 auto;
		background: url(../images/package.png) no-repeat 30px top;
		padding-top: 95px;
			
		dl {
			width: 100%;
			padding-left: 235px;
			padding-bottom: 45px;
			
			dt {
				@include fz($size: 44);
				font-style: normal;
				font-weight: 900;
				color: #2aa738;
				line-height: 1.2;
				margin-bottom: 5px;
			}
			dd {
				@include fz($size: 24);
				font-style: normal;
				font-weight: 900;
				color: #2aa738;
				line-height: 1.2;
			}
		}
	}
	
	@include e(_img) {
		img {
			width: 100%;
			height: auto;
		}
	}
}


/*-----------------------------------------------------*/
// return
/*-----------------------------------------------------*/

.return {
	text-align: center;
	margin-bottom: 80px;
	
	a {
		display: inline-block;
		@include fz($size: 16);
		font-style: normal;
		font-weight: 700;
		letter-spacing: .1em;
		text-decoration: none;
		color: #000;
		border-bottom: 2px solid #000;
		background: url(../images/return_mark.png) no-repeat left 1px;
		padding-left: 20px;
		line-height: 1.3;
		
		&:hover {
			opacity: .5;
		}
	}
}


/*-----------------------------------------------------*/
// footer
/*-----------------------------------------------------*/

.footer {
	width: 100%;
	background: #f2f2f2;
	position: relative;
	
	@include e(_pagetop) {
		position: absolute;
		width: 100%;
		top: -35px;
		left: 0;
		
		p {
			max-width: 1270px;
			width: 100%;
			margin: 0 auto;
			padding: 0 20px;
			text-align: right;
			
			a {
				&:hover {
					opacity: .5;
				}
			}
		}
	}
	
	@include e(_copy) {
		@include fz($size: 12);
		font-family: $font-arial;
		letter-spacing: .05em;
		text-align: center;
		padding: 60px 0;
		color: #1d1d1d;
	}
}


/*-----------------------------------------------------*/
// modal
/*-----------------------------------------------------*/

.modal {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	margin: -135px 0 0 -250px;
	width: 500px;
	height: 270px;
	background: #fff;
	text-align: center;
	border-radius: 10px;
	z-index: 101;
	
	&::after {
		position: relative;
	}
	
	@include e(_close) {
		position: absolute;
		top: 10px;
		right: 10px;
		
		a {
			text-decoration: none;
			color: #000;
			@include fz($size: 16);
			font-style: normal;
			font-weight: 400;
			
			&:hover {
				opacity: .5;
			}
		}
	}
	
	h4 {
		padding-top: 30px;
		@include fz($size: 16);
		font-style: normal;
		font-weight: 700;
		margin-bottom: 20px;
	}
	ul {
		width: 87%;
		display: flex;
		justify-content: space-between;
		margin: 0 auto 35px auto;
		
		li {
			width: 47%;
			a {
				display: inline-block;
				width: 100%;
				max-width: 220px;
				border-radius: 6px;
				color: #fff;
				background: #2aa738;
				@include fz($size: 16);
				font-style: normal;
				font-weight: 700;
				text-align: center;
				padding: 20px 5px 16px 5px;
				line-height: 1;
				text-decoration: none;
				letter-spacing: .05em;
				
				&:hover {
					opacity: .5;
				}
			}
		}
	}
		
	dl {
		text-align: center;
		
		dt {
			@include fz($size: 16);
			font-style: normal;
			font-weight: 700;
			margin-bottom: 10px;
		}
		dd {
			@include fz($size: 13);
			font-style: normal;
			font-weight: 400;
			line-height: 1.6;
		}
	}
}
.modal__bg {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background: rgba(0,0,0,.5);
	z-index: 100;
}


/*-----------------------------------------------------*/
// effects
/*-----------------------------------------------------*/

.fade {
	opacity: 0;
	transform: translate(0,30px); 
	-webkit-transform: translate(0,30px);
	transition: 1.3s;
}
.fade_on {
	opacity: 1.0;
	transform: translate(0,0); 
	-webkit-transform: translate(0,0);
}


/*-----------------------------------------------------*/
// Smartphone
/*-----------------------------------------------------*/

@media only screen and (max-width: 769px) {
	@import "_sp";
}